<template>
  <v-select
      :items="datas"
      label="Sınav Türü"
      :item-text="(item) => item.Name"
      :item-value="(item) => item.Id"
      v-bind="$attrs"
      outlined
      clearable
      hide-details
      dense
      v-on="$listeners"
      :filter="filterTR"
  >
  </v-select>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'core-exam-kind',
  props: ['forStudent'],
  data() {
    return {
      datas: [],
    }
  },
  watch: {
    forStudent: function () {
      this.getDataFromApi()
    }
  },
  mounted: function () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/ExamKind', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          ForStudent: this.forStudent
        }
      }).then((data) => {
        this.datas = data.data.Results
      }).catch(({response}) => {
        ApiService.showError(response)
      })
    }
  }
}
</script>
