<template>
  <v-select
      :items="datas"
      label="Sınav Sonuç Grubu"
      item-text="Name"
      item-value="Id"
      v-bind="$attrs"
      outlined
      v-on="$listeners"
      :filter="filterTR"
  >
  </v-select>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'core-exam-result-lesson-group',
  data() {
    return {datas: [], editedItem: {Name: '', Id: 0}, dialog: false, loadingSave: false}
  },
  mounted: function () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/ExamResultLessonGroup', {
        sortBy: ['DisplayOrder'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      }).then((data) => {
        this.datas = data.data.Results
      }).catch(({response}) => {
        ApiService.showError(response)
      })
    }
  }
}
</script>
